import React from "react";

import himil from "../img/himil.jpeg";
import jose from "../img/jose.jpeg";
import julia from "../img/julia.jpeg";
import nick from "../img/nick.jpeg";
import grant from "../img/grant.jpg";
import bhumika from "../img/bhumika.jpeg";
import mihir from "../img/mihir.jpeg";

export default function MeetOurTeam() {
  return (
    <div className="meet-our-team-container">
      <div className="team-header-container">
        <h1 id="meet-our-team-header">Meet Our Team</h1>
      </div>

      <div className="team-container">
        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={bhumika}
              alt="Bhumika"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Bhumkia Sood</h2>
            <p className="dev-role">Backend Developer</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={grant}
              alt="Grant"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Grant Hendrickson</h2>
            <p className="dev-role">Designer & Lead Web Devloper</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={himil}
              alt="Himil"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Himil Patel</h2>
            <p className="dev-role">Mobile Developer</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={julia}
              alt="Julia"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Loubien Demetita</h2>
            <p className="dev-role">Web Developer</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={mihir}
              alt="Mihir"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Mihir Araveeti</h2>
            <p className="dev-role">Mobile Developer</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={jose}
              alt="Mihir"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Mihir Patel</h2>
            <p className="dev-role">Backend & Mobile Developer</p>
          </div>
        </div>

        <div className="dev-container">
          <div className="dev-image-container">
            <img
              className="team-pic"
              src={nick}
              alt="Nick"
            />
          </div>
          <div className="dev-details-container">
            <h2 className="dev-name">Nicolas Gonzalez</h2>
            <p className="dev-role">Project Manager & Backend Developer</p>
          </div>
        </div>
      </div>
    </div>
  );
}
